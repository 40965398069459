.blines {
  width: clamp(45ch, 50%, 75ch);
}

.elemSpaceBetween + .elemSpaceBetween {
  margin-left: 0.6rem;
}

.elemSpaceTop + .elemSpaceTop {
  margin-top: 0.6rem;
}

.nav-active {
  background-color: blue;
  /* border-radius: 32%; */
}
